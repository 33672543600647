body { 
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Poppins', sans-serif
};

h1,h2,h3,h4,h5,h6,p { margin: 0 };

.color-overlay {
  background: linear-gradient(200deg, rgb(14, 1, 32), rgb(51, 51, 250));
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  opacity: .4;
};

.hero {
  min-height: 100vh;
  position: relative;
};

.hero h1 {
  position: relative;
  color: white;
  
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.64);
  letter-spacing: 2px;
  z-index: 3;
};

.hero img {
  position: absolute;
  left:0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
  mix-blend-mode: multiply;
}

.hero video {
  position: absolute;
  left:0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}